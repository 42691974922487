import React from 'react'
import { Link } from 'react-router-dom'

// MUI Components
import Grid from '@mui/material/Grid'
import { ThemeProvider, createTheme } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import Typography from '@mui/material/Typography'

// Custom CSS
import css from './Footer.module.css'

let theme = createTheme({
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const Footer = () => {
    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                component={'footer'}
                paddingX={{
                    md: 20,
                    xs: 1
                }}
                className={`${css.container} ${css.elevate}`}
                marginBottom={{
                    md: 0,
                    sm: 9,
                    xs: 9,
                }}
                bgcolor={blueGrey[50]}
            >
                <Grid sx={{
                    display: 'flex',
                    flexDirection: {
                        md: 'column',
                        sm: 'row',
                        xs: 'row',
                    },
                    justifyContent: {
                        sm: 'space-between',
                        xs: 'space-between',
                    },
                    width: {
                        lg: 'auto',
                        md: 'auto',
                        sm: '100%',
                        xs: '100%',
                    }
                }}>
                    <Typography
                        component={'h4'}
                        className={css.heading}
                        sx={{
                            width: {
                                lg: '50%',
                                md: '50%',
                                sm: '20%',
                                xs: '20%',
                            },
                            fontSize: {
                                md: '1rem',
                                sm: '12px',
                                xs: '12px',
                            }
                        }}
                    >
                        Indian Stock Pulse
                    </Typography>
                    <Typography
                        variant='p'
                        component={'p'}
                        sx={{
                            fontSize: {
                                lg: 'large',
                                md: 'large',
                                sm: '12px',
                                xs: '12px',
                            }
                        }}
                    >
                        We Make it Possible for you.
                    </Typography>
                </Grid>
                <Grid sx={{
                    display: {
                        md: 'block',
                        sm: 'none',
                        xs: 'none'
                    }
                }}>
                    <Typography
                        component={'h4'}
                        className={css.subheading}>Services</Typography>
                    <Grid>
                        <Link
                            to={`/${'intraday'}`}
                            className={css.links}>Intraday</Link>
                    </Grid>
                    <Grid>
                        <Link
                            to={`/${'short-term'}`}
                            className={css.links}>Short-term</Link>
                    </Grid>
                    <Grid>
                        <Link
                            to={`/${'mid-term'}`}
                            className={css.links}>Mid-term</Link>
                    </Grid>
                    <Grid>
                        <Link
                            to={`/${'long-term'}`}
                            className={css.links}>Long-term</Link>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default Footer