import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'

// MUI components and icons
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuItem from '@mui/material/MenuItem'
import { blueGrey } from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import PersonIcon from '@mui/icons-material/Person'
import BottomNavigation from '@mui/material/BottomNavigation'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'

// assets
import css from './Header.module.css'
import aboutIcon from '../../../../assets/about-button-32.png'
import contactIcon from '../../../../assets/contact-button-32.png'
import { ReactComponent as HomeIcon } from '../../../../assets/home-icon.svg'

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Poppins",
        }
    },
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const navItems = [{
    id: 1,
    name: 'Home',
    link: '/'
},
{
    id: 2,
    name: 'About',
    link: '/about'
},
{
    id: 3,
    name: 'Contact',
    link: '/contact'
}];

const Header = () => {

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', marginBottom: 7, paddingInline: 10 }}>
                <AppBar component="nav">
                    <Toolbar>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: {
                                    sm: 'block'
                                },
                                color: '#000',
                            }}
                        >
                            <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>Indian Stock Pulse</Link>
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: '1rem' }}>
                            {BasicMenu()}

                            {/* Large Screens Menu */}
                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                {navItems.map((item) => (
                                    <Link key={item.id} to={item.link} className={css.text_decoration_none}>
                                        <Button sx={{
                                            color: '#212121',
                                            ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50] }
                                        }}>{item.name}</Button>
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                    </Toolbar>
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={24}>
                        <BottomNavigation
                            showLabels
                            sx={{
                                width: '100%',
                                display: {
                                    lg: 'none',
                                    md: 'none',
                                    sm: 'flex',
                                },
                                justifyContent: 'space-evenly',
                                fontFamily: 'Poppins',
                                py: 1,
                            }}>
                            <BottomNavigationAction
                                sx={{
                                    fontWeight: 'bold',
                                    '& span': {
                                        fontFamily: 'Poppins',
                                    }
                                }}
                                label="Home"
                                component={Link}
                                to={"/"}
                                icon={<HomeIcon width="32px" />}
                            />
                            <BottomNavigationAction
                                sx={{
                                    fontWeight: 'bold',
                                    '& span': {
                                        fontFamily: 'Poppins',
                                    }
                                }}
                                label="Contact"
                                component={Link}
                                to={"/contact"}
                                icon={<img src={contactIcon} alt="" />}
                            />
                            <BottomNavigationAction
                                sx={{
                                    fontWeight: 'bold',
                                    '& span': {
                                        fontFamily: 'Poppins',
                                    }
                                }}
                                label="About"
                                component={Link}
                                to={"/about"}
                                icon={<img src={aboutIcon} alt="" />}
                            />
                        </BottomNavigation>
                    </Paper>
                </AppBar>
            </Box>
        </ThemeProvider>
    )
}

export default memo(Header)

function BasicMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant='contained' sx={{
                    backgroundColor: '#fafafa',
                    color: '#212121',
                    marginLeft: 'auto',
                    ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50] },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1
                }}
                onClick={handleClick}
            >
                <PersonIcon /> Account
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    '.MuiMenu-list': { p: 0, backgroundColor: '#fafafa' }
                }}
            >
                <Link to={'/user/login'} className={css.text_decoration_none}>
                    <MenuItem onClick={handleClose} sx={{
                        color: '#212121',
                        marginLeft: 2,
                        ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50], textShadow: '0 0 4px #fff,0 0 6px #fff,0 0 10px #fff,0 0 4px #228dff,0 0 6px #228dff,0 0 10px #228dff' },
                        m: 0,
                        p: '.5rem 2rem',
                        transition: 'all',
                        transitionDuration: '300ms',
                        textShadow: 0
                    }}>Login</MenuItem>
                </Link>
                <Link to={'/user/signup'} className={css.text_decoration_none}>
                    <MenuItem onClick={handleClose} sx={{
                        color: '#212121',
                        marginLeft: 2,
                        ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50], textShadow: '0 0 4px #fff,0 0 6px #fff,0 0 10px #fff,0 0 4px #228dff,0 0 6px #228dff,0 0 10px #228dff' },
                        m: 0,
                        p: '.5rem 2rem',
                        transition: 'all',
                        transitionDuration: '300ms',
                        textShadow: 0
                    }}>Sign Up</MenuItem>
                </Link>
            </Menu>
        </div>
    );
}