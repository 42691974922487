import React, { lazy, Suspense } from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

// Skeleton
import { blueGrey } from '@mui/material/colors'
import { createTheme, ThemeProvider } from '@mui/material'
import LoadingSkele from './components/ui-skeleton/LoadingSkele'

// Website routes
import Header from './components/website/common/header/Header'
import Footer from './components/website/common/footer/Footer'
import PanelHeader from './components/panel/common/panelHeader/PanelHeader'
import Logout from './components/panel/pages/settings/Logout'
import AppLogout from './AppLogout'
import Trade from './components/panel/pages/trade/Trade'
import { useModeContext } from './components/panel/contexts/ModeContext'
const Home = lazy(() => import('./components/website/home/Home'))
const Contact = lazy(() => import('./components/website/contact/Contact'))
const About = lazy(() => import('./components/website/about/About'))
const Login = lazy(() => import('./components/website/auth/login/Login'))
const SignUp = lazy(() => import('./components/website/auth/signup/SignUp'))
const Service = lazy(() => import('./components/website/Service/Service'))

// Panel
const Profile = lazy(() => import('./components/panel/pages/settings/Profile'))
// const WatchList = lazy(() => import('./components/panel/pages/watchlist/WatchList'))
const Dashboard = lazy(() => import('./components/panel/pages/dashboard/Dashboard'))
const Orders = lazy(() => import('./components/panel/pages/orders/Orders'))
const Account = lazy(() => import('./components/panel/pages/account/Account'))
const AccountDetails = lazy(() => import('./components/panel/pages/settings/AccountDetails'))
const AddMoney = lazy(() => import('./components/panel/pages/account/AddMoney'))
const WithdrawMoney = lazy(() => import('./components/panel/pages/account/WithdrawMoney'))
const MarketChart = lazy(() => import('./components/panel/pages/dashboard/MarketChart'))

// assets
const basicImg = require('./assets/user_default.png')
const RegularScreenBannerImg = require('./assets/banner-md.avif')
const SmallScreenBannerImg = require('./assets/banner-sm.avif')

const user = JSON.stringify({
  fullName: '',
  contactNo: '',
  email: '',
  aadharNo: '',
  panNo: '',
  accountNo: '',
  ifsc: '',
  profile: basicImg,
  username: '',
  password: '',
  userID: null,
  authenticated: false
})

if (localStorage.user === undefined) {
  localStorage.setItem('user', user)
}

const getId = (index) => {
  const id = window.location.pathname.split('/')
  const user = JSON.parse(localStorage.user)
  if (user !== undefined) {
    if (user.userID !== null && user.userID.toString() === id[index]) {
      return true
    } else {
      return false
    }
  } else return false
}

const PrivateRoute = ({ component, index }) => {
  const { mode } = useModeContext()

  const theme = createTheme({
    palette: {
      primary: {
        light: mode ? blueGrey[900] : blueGrey[50],
        main: mode ? blueGrey[900] : blueGrey[50],
        dark: mode ? blueGrey[900] : blueGrey[50]
      },
      secondary: {
        light: mode ? blueGrey[200] : blueGrey[900],
        main: mode ? blueGrey[200] : blueGrey[900],
        dark: mode ? blueGrey[200] : blueGrey[900]
      },
    },
  })

  // document.getElementById('main').style.maxWidth = '50vw'
  // document.getElementById('main').style.marginInline = 'auto'

  return getId(index) ? <ThemeProvider theme={theme}>
    {component}
  </ThemeProvider> : (
    <>
      {alert('You are not authrized!')}
      <Navigate to="/user/login" />
    </>
  )
}

const App = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route path='/' element={
        <>
          <Header />
          <Suspense fallback={<LoadingSkele styles={{
            marginTop: 9
          }} />}>
            <Home
              SmallScreenBannerImg={SmallScreenBannerImg}
              RegularScreenBannerImg={RegularScreenBannerImg}
            />
          </Suspense>
          <Footer />
        </>
      } />
      <Route path='/contact' element={
        <>
          <Header />
          <Suspense fallback={<LoadingSkele styles={{
            marginTop: 9
          }} />}>
            <Contact />
          </Suspense>
          <Footer />
        </>
      } />
      <Route path='/about' element={
        <>
          <Header />
          <Suspense fallback={<LoadingSkele styles={{
            marginTop: 9
          }} />}>
            <About
              SmallScreenBannerImg={SmallScreenBannerImg}
              RegularScreenBannerImg={RegularScreenBannerImg}
            />
          </Suspense>
          <Footer />
        </>
      } />
      <Route path='/services/:service' element={
        <Suspense fallback={<LoadingSkele styles={{
          marginTop: 0
        }} />}>
          <Service />
        </Suspense>
      } />
      <Route path='/user/signup' element={
        <Suspense fallback={<LoadingSkele styles={{
          marginTop: 0
        }} />}>
          <SignUp />
        </Suspense>
      } />
      <Route path='/user/login' element={
        <Suspense fallback={<LoadingSkele styles={{
          marginTop: 0
        }} />}>
          <Login />
        </Suspense>
      } />

      {/* Private Routes */}
      {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}
      <Route>
        {/* Dashboard Route Start */}
        <Route
          path='/dashboard/:userID'
          element={<PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <Dashboard />
              </Suspense>
            </AppLogout>
          } index={2} />} />

        <Route
          path='/dashboard/:userID/:market'
          element={<PrivateRoute component={
            // <AppLogout>
            <>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <MarketChart />
              </Suspense>
            </>
            // </AppLogout>
          } index={2} />} />
        {/* Dashboard Route End */}

        {/* User Routes Start */}
        <Route path='/user/profile/:userID' element={
          <PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <Profile />
              </Suspense>
            </AppLogout>
          } index={3} />
        } />

        <Route path='/user/account-details/:userID' element={
          <PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <AccountDetails />
              </Suspense>
            </AppLogout>
          } index={3} />
        } />

        <Route path='/user/add-money/:userID' element={
          <PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <AddMoney />
              </Suspense>
            </AppLogout>
          } index={3} />
        } />

        <Route path='/user/withdraw-money/:userID' element={
          <PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <WithdrawMoney />
              </Suspense>
            </AppLogout>
          } index={3} />
        } />

        <Route path='/user/logout/:id' element={<Logout />} />
        {/* User Routes End */}

        {/* Account Route Start */}
        <Route path='/account/:userID' element={
          <PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <Account />
              </Suspense>
            </AppLogout>
          } index={2} />
        } />
        {/* Account Route End */}

        {/* Orders Route Start */}
        <Route path='/orders/:userID' element={
          <PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <Orders />
              </Suspense>
            </AppLogout>
          } index={2} />
        } />
        {/* Orders Route End */}

        {/* Watchlist Route Start */}
        {
          /*
          <Route path='/watchlist/:userID' element={
          <PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <WatchList />
              </Suspense>
            </AppLogout>
          } index={2} />
        } />
          */
        }
        {/* Watchlist Route End */}

        {/* Trade Purchase Route Start */}
        <Route path='/trade/:userID' element={
          <PrivateRoute component={
            <AppLogout>
              <PanelHeader />
              <Suspense fallback={<LoadingSkele styles={{
                marginTop: 0,
              }} />}>
                <Trade />
              </Suspense>
            </AppLogout>
          } index={2} />
        } />
        {/* Trade Purchase Route End */}
      </Route>
    </React.Fragment>
  )
)

export default App;
